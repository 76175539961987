<template>
  <div style="overflow: hidden; position: relative;">
    <div class="r-header">
      <el-date-picker v-model="visitTimeStart" type="datetime" placeholder="预约时间起" value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>
      <el-button type="primary" @click="onSearch('search')">查询</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;" max-height="654" border v-loading="loading" ref="report-table">
      <el-table-column fixed prop="username" label="姓名"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="idCard" label="身份证号"></el-table-column>
      <el-table-column prop="reason" label="来访事由"></el-table-column>
      <el-table-column prop="visitorNum" label="来访人数"></el-table-column>
      <el-table-column prop="visitTimeStart" label="来访时间起" width="170"></el-table-column>
      <el-table-column prop="visitTimeEnd" label="来访时间止" width="170"></el-table-column>
      <el-table-column prop="interviewee" label="受访人"></el-table-column>
      <el-table-column prop="visPhone" label="受访人手机号"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button @click.native.prevent="changeRow(scope.$index, tableData)" icon="el-icon-view" type="text">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
      layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>

    <el-dialog title="内容详情页" :visible.sync="dialogVisible" width="40%" top="18vh" @close="dialogVisible = false"
      :modal-append-to-body="false" :append-to-body="true">
      <div class="cont-box">
        <el-form :inline="true" :model="rosterImport" class="my-form" ref="rosterImport">
          <el-form-item prop="username" label="姓名">
            <el-input v-model="rosterImport.username" disabled></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="手机号">
            <el-input v-model="rosterImport.phone" disabled></el-input>
          </el-form-item>
          <el-form-item prop="idCard" label="身份证号">
            <el-input v-model="rosterImport.idCard" disabled></el-input>
          </el-form-item>
          <el-form-item prop="reason" label="来访事由">
            <el-input v-model="rosterImport.reason" disabled></el-input>
          </el-form-item>
          <el-form-item prop="visitorNum" label="来访人数">
            <el-input v-model="rosterImport.visitorNum" disabled></el-input>
          </el-form-item>
          <el-form-item prop="visitTimeStart" label="来访时间起">
            <el-input v-model="rosterImport.visitTimeStart" disabled></el-input>
          </el-form-item>
          <el-form-item prop="visitTimeEnd" label="来访时间止">
            <el-input v-model="rosterImport.visitTimeEnd" disabled></el-input>
          </el-form-item>
          <el-form-item prop="interviewee" label="受访人">
            <el-input v-model="rosterImport.interviewee" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item prop="visPhone" label="受访人手机号">
            <el-input v-model="rosterImport.visPhone" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item prop="remark" label="备注">
            <el-input type="textarea" v-model="rosterImport.remark" disabled></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import XLSX from 'xlsx'
  import FileSaver from 'file-saver'

  import ElXlsx from '../../components/ElXlsx.vue'
  import RosterConfirm from '../../components/RosterConfirm.vue'
  export default {
    name: 'Roster',
    components: {
      ElXlsx,
      RosterConfirm
    },
    data() {
      return {
        loading: true,
        dialogVisible: false,
        visitTimeStart: '',
        rosterImport: {},
        tableData: [],
        curData: {},
        page: 1,
        rows: 10,
        total: null
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList(e) {
        this.loading = true
        if (e === 'search') {
          this.page = 1
        }
        await this.$axios.post('/visitor-record/findList', {
          current: this.page,
          size: this.rows,
          visitTimeStart: this.visitTimeStart
        }).then(res => {
          this.loading = false
          this.tableData = res.data.data.records
          this.total = res.data.data.total || res.data.data.records.length
        })
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      onSearch(e) {
        this.getList(e)
      }
    }
  }
</script>

<style scoped>
  .r-header {
    display: flex;
    margin-bottom: 20px;
  }

  .r-header .el-input {
    width: 324px !important;
    margin-right: 6px;
  }

  .r-header>>>.el-button {
    margin-left: 0;
  }

  .el-table {
    box-sizing: border-box;
  }

  .drawer-cont {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .el-tag {
    /* cursor: pointer; */
    padding: 0 24px;
  }

  .el-table>>>.cell .el-button {
    padding: 0;
  }

  /*  .el-dialog__wrapper {
    height: 100%;
  }
  .el-dialog__wrapper >>> .el-dialog {
    height: 80%;
    overflow-y: auto;
  } */
  .cont-box {
    padding: 0 10px;
    /* overflow-y: auto; */
  }

  .list-cont {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont li {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
  }

  .list-cont01>div {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
    margin-bottom: 0;
  }

  .title {
    line-height: 34px;
    background: #f9f9f9;
    text-indent: 1em;
    color: #999999;
  }

  .list-cont li>label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__content {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .list-cont li>p {
    flex: 1;
    color: #323232;
    margin: 0 10px;
    min-height: 20px;
    border-bottom: 1px solid #dedede;
  }

  .list-cont li>.el-input,
  .list-cont li>.el-select,
  .list-cont li>.el-textarea {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }

  .my-form {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .my-form>.el-form-item {
    box-sizing: border-box;
    width: 50%;
    margin: 0;
    padding: 10px;
    display: flex;
  }

  .my-form>>>.el-form-item__label {
    white-space: nowrap;
  }

  .my-form>>>.el-form-item__content {
    flex: 1;
  }

  .upload {
    display: inline-block;
    margin-right: 6px;
  }

  .el-button {
    margin-right: 6px;
  }
</style>
